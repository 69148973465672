import styled from 'styled-components'

export const AppFooter = styled.div`
  margin-top: auto;
  padding: 0 60px;
  width: stretch;
  @media (max-width: 768px) {
    padding: 20px 12px 0;
    background: url(../images/nft/bg-3.png) no-repeat 0 0;
    background-size: 100%;
  }
`

export const TransparentFooter = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 56px;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 24px;
  }
  @media (max-width: 768px) {
    padding: 12px;
    gap: 12px;
  }
`

export const FooterLogo = styled.img`
  width: 193px;
  height: 53px;
  @media (max-width: 768px) {
    width: 100px;
    height: 28px;
  }
`

export const FooterNav = styled.nav`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-left: auto;
  @media (max-width: 1280px) {
    margin-left: 0;
    flex-wrap: wrap;
    max-width: 270px;
  }
`

export const NavLink = styled.a`
  line-height: 0;
`

export const FooterInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  justify-content: space-between;
  color: rgba(255, 255, 255, 0.4);
  @media (max-width: 1280px) {
    flex-basis: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const FooterInfo = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;
  display: flex;
  gap: 36px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
`

export const FooterInfoText = styled.p`
  margin: 0;
  display: inline;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;
  @media (max-width: 768px) {
    display: block;
  }
`

export const FooterInfoEmail = styled.a`
  font-family: 'Nexa';
  color: rgba(255, 255, 255, 0.4);
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: center;
`
