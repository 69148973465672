import styled from 'styled-components'

export const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 60px 0;
  @media (max-width: 991px) {
    padding: 130px 18px 0;
  }
`

export const MainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 991px) {
    align-items: center;
  }
`
export const MainContainerInfo = styled.div`
  max-width: 764px;
  @media (max-width: 768px) {
    max-width: 320px;
  }
`
export const MainTitle = styled.h1`
  font-family: Gagalin;
  font-size: 83px;
  font-weight: 400;
  line-height: 85%;
  letter-spacing: -0.02em;
  text-align: center;
  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 561.634px rgba(253, 29, 143, 0.6),
    0px 0px 320.934px rgba(253, 29, 143, 0.6),
    0px 0px 93.6056px rgba(253, 29, 143, 0.6),
    0px 0px 26.7445px rgba(253, 29, 143, 0.6);

  margin: 0;
  @media (max-width: 768px) {
    font-size: 50px;
  }
`

export const MainTitleContainer = styled.div`
  margin-bottom: 60px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`

export const MainSubtitle = styled.p`
  font-family: Gagalin;
  font-size: 90px;
  font-weight: 400;
  line-height: 85%;
  letter-spacing: -0.02em;
  text-align: center;
  background: linear-gradient(241.11deg, #ff7cd4 32.22%, #ff3abf 90.48%);
  text-shadow: 0 0 13.3722px rgb(253 29 143 / 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  width: max-content;
  margin: auto;
  @media (max-width: 768px) {
    font-size: 60px;
  }
`
export const MainSubtitleContainer = styled.div`
  background-image: url(${require(`../../images/SubtitleBack.svg`).default});
  background-position: center 10px;
  background-size: 213px;
  background-repeat: no-repeat;
  padding-bottom: 5px;
  @media (max-width: 768px) {
    background-position: center 0;
    background-size: 150px;
    background-position: center 5px;
  }
`
