import { createGlobalStyle } from 'styled-components'

import GagalinWoff from './Gagalin/Gagalin.woff'
import GagalinWoff2 from './Gagalin/Gagalin.woff2'
import GagalinTtf from './Gagalin/Gagalin.ttf'

import NexaWoff from './Nexa/Nexa.woff'
import NexaWoff2 from './Nexa/Nexa.woff2'
import NexaTtf from './Nexa/Nexa.ttf'

import NexaBlackWoff from './NexaBlack/Nexa-Black.woff'
import NexaBlackWoff2 from './NexaBlack/Nexa-Black.woff2'
import NexaBlackTtf from './NexaBlack/Nexa-Black.ttf'

const GlobalFonts = createGlobalStyle`

  @font-face {
    font-family: 'Gagalin';
    src: local('Gagalin'), local('Gagalin-Regular'),
    url(${GagalinWoff2}) format('woff2'),
    url(${GagalinWoff}) format('woff'),
    url(${GagalinTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nexa';
    src: local('Nexa'), local('Nexa-Regular'),
    url(${NexaWoff2}) format('woff2'),
    url(${NexaWoff}) format('woff'),
    url(${NexaTtf}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nexa';
    src: local('Nexa Black'), local('Nexa-Black'),
    url(${NexaBlackWoff2}) format('woff2'),
    url(${NexaBlackWoff}) format('woff'),
    url(${NexaBlackTtf}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

`

export { GlobalFonts }
