import {
  AppFooter,
  TransparentFooter,
  FooterLogo,
  FooterNav,
  NavLink,
  FooterInfo,
  FooterInfoContainer,
  FooterInfoText,
  FooterInfoEmail,
} from './Footer.styled'

function Footer() {
  return (
    <AppFooter>
      <TransparentFooter>
        <FooterLogo
          src={require('../../images/Logo.svg').default}
          alt='logo'
          width='150px'
          height='42px'
        />
        <FooterInfoContainer>
          <FooterInfo>
            <FooterInfoText>
              Copyright {new Date().getFullYear()}
            </FooterInfoText>
            <FooterInfoEmail href='mailto:bussiness@metarun.com'>
              bussiness@metarun.com
            </FooterInfoEmail>
          </FooterInfo>
        </FooterInfoContainer>
        <FooterNav>
          <NavLink href='https://discord.gg/metarungame' target='_blank'>
            <img
              src={require('../../images/Discord.svg').default}
              alt='discord'
              width='36px'
              height='36px'
            />
          </NavLink>
          <NavLink href='https://twitter.com/MetarunGame' target='_blank'>
            <img
              src={require('../../images/Twitter.svg').default}
              alt='twitter'
              width='36px'
              height='36px'
            />
          </NavLink>
          <NavLink href='https://t.me/metarungame' target='_blank'>
            <img
              src={require('../../images/Telegram.svg').default}
              alt='telegram'
              width='36px'
              height='36px'
            />
          </NavLink>
          <NavLink href='https://metarungame.medium.com/' target='_blank'>
            <img
              src={require('../../images/Medium.svg').default}
              alt='medium'
              width='36px'
              height='36px'
            />
          </NavLink>
          <NavLink href='http://bit.ly/3xonXH4' target='_blank'>
            <img
              src={require('../../images/Youtube.svg').default}
              alt='youtube'
              width='36px'
              height='36px'
            />
          </NavLink>
          <NavLink
            href='https://www.instagram.com/metarun_official'
            target='_blank'
          >
            <img
              src={require('../../images/Instagram.svg').default}
              alt='instagram'
              width='36px'
              height='36px'
            />
          </NavLink>
          <NavLink
            href='https://www.linkedin.com/company/metarun'
            target='_blank'
          >
            <img
              src={require('../../images/Linkedin.svg').default}
              alt='linkedin'
              width='36px'
              height='36px'
            />
          </NavLink>
          <NavLink href='https://www.facebook.com/metarungame' target='_blank'>
            <img
              src={require('../../images/Facebook.svg').default}
              alt='facebook'
              width='36px'
              height='36px'
            />
          </NavLink>
        </FooterNav>
      </TransparentFooter>
    </AppFooter>
  )
}

export default Footer
