import { useState, useEffect } from 'react'
import Progress from '../Progress'
import { getSaleInfo } from '../../api'
import {
  SaleContainer,
  SaleMenu,
  SaleMenuDivider,
  SaleButton,
} from './Sale.styled'

function Sale({ userAddress, chainId }) {
  const [saleStatus, setSaleStatus] = useState('WL Mint')

  const [soldBoxes, setSoldBoxes] = useState(0)
  const [maxBoxesToSale, setMaxBoxesToSale] = useState(0)
  const [referral, setReferral] = useState('')

  async function getSaleInfos() {
    await getSaleInfo()
      .then(res => {
        setSoldBoxes(res.soldBoxes)
        setMaxBoxesToSale(res.maxBoxesToSale)
        setSaleStatus(res.saleStatus)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getSaleInfos()
  }, [])

  useEffect(() => {
    setReferral(
      window.localStorage.getItem('Referral')
        ? `?mysteryreferral=${window.localStorage.getItem('Referral')}`
        : ''
    )
  }, [localStorage])

  return (
    <SaleContainer type={saleStatus}>
      <SaleMenu>
        <Progress totalNumber={maxBoxesToSale} currentNumber={soldBoxes} />
      </SaleMenu>
      <SaleMenuDivider />
      <a href={`https://marketplace.metarun.game/${referral}`}>
        <SaleButton>BUY FOR 99$ NOW</SaleButton>
      </a>
    </SaleContainer>
  )
}

export default Sale
