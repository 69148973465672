import styled from 'styled-components'

export const SaleContainer = styled.div`
  margin: 0 auto;
  background-image: url(${require(`../../images/SaleBack.png`)});
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 647px;
  height:573px;

  box-sizing: border-box;
  @media (max-width: 768px) {
    background-size: 320px;
    width: 320px;
    height: 285px;
  }
`

export const SaleMenu = styled.ul`
  margin: 0;
  list-style: none;
  padding: 45px 45px 20px;
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 32px 32px 4px;
    gap: 5px;
    justify-content: space-around;
  }
`

export const SaleMenuDivider = styled.div`
  background-image: url(${require(`../../images/Divider.png`)});
  background-repeat: no-repeat;
  height: 74px;
  width: 712px;
  align-self: start;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    background-size: 340px;
    width: 340px;
    height: 40px;
    margin-bottom: 16px;
  }
`

export const SaleButton = styled.button`
  font-family: Nexa;
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 900;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #1c162f;
  margin-bottom: 60px;
  margin-top: 60px;
  background-color: transparent;
  border: none;
  width: 445px;
  height: 107px;
  background-image: url(${require(`../../images/ButtonBack.png`)});
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.5s all;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
  &:disabled,
  &[disabled] {
    opacity: 0.6;
  }
  &:disabled:active,
  &[disabled]&:active,
  &:disabled:hover,
  &[disabled]&:hover {
    transform: none;
  }
  @media (max-width: 768px) {
    margin-top: 24px;
    width: 200px;
    height: 50px;
    background-size: 200px;
    font-size: 22px;
    margin-bottom: 13px;
    line-height: 26px;
  }
`
