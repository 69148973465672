import styled from 'styled-components'

export const ProgressContainer = styled.div`
  height: 7px;
  width: 522px;
  background: #464156;
  border-radius: 8px;
  margin-bottom: 18px;
  @media (max-width: 768px) {
    width: 260px;
    height: 5px;
    margin-bottom: 9px;
  }
`
export const ProgressFiller = styled.div`
  height: 100%;
  width: ${({ completed }) => {
    if (completed) {
      return `${completed}%`
    }
    return '0'
  }};
  max-width: 100%;
  background: #f838b8;
  border-radius: inherit;
  text-align: right;
  box-shadow: 0px 4px 14px rgba(248, 56, 184, 0.55);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    right: -6px;
    background: #f838b8;
    box-shadow: 0px 4px 24px #f838b8;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  @media (max-width: 768px) {
    &:before {
      top: -3px;

      width: 12px;
      height: 12px;
    }
  }
`

export const ProgressText = styled.p`
  margin: 0;
  font-family: Nexa;
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
