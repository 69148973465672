import { formatDate } from '../utils/formatDate'
const host = process.env.REACT_APP_API_HOST
const referralHost = 'https://hooks.zapier.com/hooks/catch/1955480/b0mmaki/'

export async function getSaleInfo() {
  return await fetch(`${host}/salestatus.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then(response => response.json())
    .catch(err => {
      throw new Error(err)
    })
}

export async function setReferral(referral, userAddress, amount) {
  return await fetch(`${referralHost}`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referral,
      address: userAddress,
      amount,
      datetime: formatDate(new Date()),
    }),
  })
    .then(response => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch(err => {
      throw new Error(err)
    })
}
