import {
  ProgressContainer,
  ProgressFiller,
  ProgressText,
} from './Progress.styled'

export default function Progress({ totalNumber, currentNumber }) {
  const completed =
    currentNumber === 0 ? 0 : Math.floor((currentNumber * 100) / totalNumber)

  return (
    <>
      <ProgressContainer>
        <ProgressFiller completed={completed} />
      </ProgressContainer>
      <ProgressText>
        {currentNumber || 0} Minted / {totalNumber || 0}
      </ProgressText>
    </>
  )
}
