import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { utils } from 'ethers'
import styled from 'styled-components'
import networks from './networks.json'
import { CHAIN_ID } from './constants'
import Header from './components/Header'
import Footer from './components/Footer'
import Main from './components/Main'

const AppContainer = styled.div`
  min-height: 100vh;
  background: #12121b;
`

const AppWrapper = styled.div`
  min-height: 100vh;
  // background-image: url(${require(`./images/MainBackground.png`)});
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
`

const ConnectButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  font-family: 'Nexa';
  font-weight: 700;
  &:hover {
    opacity: 0.7;
  }
`

const ConnectLink = styled.a`
  color: white;
  &:hover {
    color: white;
  }
`

function App() {
  const dappUrl = window.location.href.split('//')[1]
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl
  const [searchParams] = useSearchParams()

  const referral = searchParams.get('referral')

  const [userAddress, setUserAddress] = useState('')
  const [chainId, setChainId] = useState('')
  const [currentCharacter, setCurrentCharacter] = useState({
    ignis: true,
    oro: false,
    penna: false,
  })

  const [currentSkin, setCurrentSkin] = useState({
    classic: true,
    epic: false,
    legendary: false,
  })
  const [inoTab, setInoTab] = useState(0)
  const [switching, setSwithing] = useState(false)

  async function connectToBlockchain() {
    if (!userAddress) {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      setUserAddress(utils.getAddress(accounts[0]))
      setSwithing(true)
    } else {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networks[CHAIN_ID].params.chainId }],
        })
      } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [networks[CHAIN_ID].params],
            })
          } catch (error) {
            console.error(error)
          }
        } else {
          console.error(error)
        }
      }
      setSwithing(false)
    }
  }

  function onAddressChanged(accounts) {
    setUserAddress(utils.getAddress(accounts[0]))
  }

  function onChainChanged() {
    window.ethereum
      .request({ method: 'eth_chainId' })
      .then(chain => setChainId(chain))
  }

  useEffect(() => {
    if (userAddress) {
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then(chain => setChainId(chain))
    }
  }, [userAddress])

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', onAddressChanged)
      window.ethereum.on('chainChanged', onChainChanged)
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAddressChanged)
        window.ethereum.removeListener('chainChanged', onChainChanged)
      }
    }
  }, [])

  useEffect(() => {
    if (switching && userAddress) {
      connectToBlockchain()
    }
  }, [switching])

  useEffect(() => {
    if (referral) {
      window.localStorage.setItem('Referral', referral)
    }
  }, [referral])

  return (
    <AppContainer>
      <AppWrapper>
        <section className='nft-banner'>
          <Header
            userAddress={userAddress}
            chainId={chainId}
            setUserAddress={setUserAddress}
          />
          <Main userAddress={userAddress} chainId={chainId} />

          <div className='ino-details'>
            <div className='nft-container'>
              <div className='ino-tabs tabs'>
                <ul className='tab-links clearfix'>
                  <li>
                    <button
                      className={` ${inoTab === 0 ? 'active' : ''}`}
                      onClick={() => setInoTab(0)}
                    >
                      MysteryBox Details
                    </button>
                  </li>
                  <li>
                    <button
                      className={` ${inoTab === 2 ? 'active' : ''}`}
                      onClick={() => setInoTab(2)}
                    >
                      ABOUT MYSTERY
                    </button>
                  </li>
                  <li>
                    <button
                      className={` ${inoTab === 4 ? 'active' : ''}`}
                      onClick={() => setInoTab(4)}
                    >
                      Series COntent
                    </button>
                  </li>
                </ul>
                <div className='tab-content'>
                  <div
                    id='tab0'
                    className={`tab ${inoTab === 0 ? 'active' : ''}`}
                  >
                    <div className='std'>
                      <p>
                        <b>No of Boxes</b> - 9999
                      </p>
                      <p>
                        <b>Price of Box</b> - 99 BUSD
                      </p>
                      <p>
                        <b>Accepted currency</b> - BUSD
                      </p>
                      <p>
                        <b>Network</b> - BNB Chain
                        {window.ethereum ? (
                          <ConnectButton onClick={connectToBlockchain}>
                            (add network)
                          </ConnectButton>
                        ) : (
                          <ConnectLink
                            href={metamaskAppDeepLink}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <ConnectButton>(add network)</ConnectButton>
                          </ConnectLink>
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    id='tab1'
                    className={`tab ${inoTab === 3 ? 'active' : ''}`}
                  >
                    <div className='std'>
                      <p>
                        A minimum of 2,000 and a maximum of 10,000 selected
                        winners for the whitelisting event will be able to
                        participate in WL mint. Each WL winner will be able to
                        mint a maximum of 5 Mysteries.
                      </p>
                      <p>
                        Non-whitelisted participants can purchase the NFT
                        Genesis Mysteries during the public mint if there are
                        unsold NFT Mystery Boxes.
                      </p>
                    </div>
                  </div>
                  <div
                    id='tab2'
                    className={`tab ${inoTab === 2 ? 'active' : ''}`}
                  >
                    <div className='std'>
                      <p>
                        Inside the NFT Mystery Box, called Mystery, you will get
                        one of 27 characters' skins. Each of the three
                        characters—Ignis, Oro, and Penna—has 9 skins, which are
                        different in NFT kind and rarity. The price of the NFTs
                        inside the Mystery Box varies from $90 to $900, while
                        the price of the NFT Mystery Box is $99.
                      </p>
                    </div>
                  </div>
                  <div
                    id='tab3'
                    className={`timeline tab ${inoTab === 1 ? 'active' : ''}`}
                  >
                    <ul className='clearfix'>
                      <li className='finished'>
                        <span className='number'>01</span>
                        <div className='infobox'>
                          <h4>ANNOUNCEMENT</h4>
                          <p>Watch the NFT trailer and stay tuned.</p>
                        </div>
                      </li>
                      <li className='finished active'>
                        <span className='number'>02</span>
                        <div className='infobox'>
                          <h4>Whitelisting</h4>
                          <p>Get your early sales entry or exclusive NFT box</p>
                        </div>
                      </li>
                      <li className=''>
                        <span className='number'>03</span>
                        <div className='infobox'>
                          <h4>WL Mint</h4>
                          <p>
                            Early sales for our list of whitelisted community
                            members
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className='number'>04</span>
                        <div className='infobox'>
                          <h4>PUBLIC MINT</h4>
                          <p>
                            Public sales based on a ‘first come, first served'
                            basis
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    id='tab4'
                    className={`tab ${inoTab === 4 ? 'active' : ''}`}
                  >
                    <table className='series'>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Supply</th>
                          <th>Chance</th>
                          <th>Rarity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-9.png' alt='img' />
                              <span>Mythical</span>
                            </h4>
                          </td>
                          <td className='normal'>9</td>
                          <td className='normal'>0.28%</td>
                          <td className='normal legendary'>Legendary</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-8.png' alt='img' />
                              <span>Rare</span>
                            </h4>
                          </td>
                          <td className='normal'>19</td>
                          <td className='normal'>0.56%</td>
                          <td className='normal legendary'>Legendary</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-7.png' alt='img' />
                              <span>Common</span>
                            </h4>
                          </td>
                          <td className='normal'>28</td>
                          <td className='normal'>0.85%</td>
                          <td className='normal legendary'>Legendary</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-6.png' alt='img' />
                              <span>Mythical</span>
                            </h4>
                          </td>
                          <td className='normal'>38</td>
                          <td className='normal'>1.12%</td>
                          <td className='normal epic'>Epic</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-5.png' alt='img' />
                              <span>Rare</span>
                            </h4>
                          </td>
                          <td className='normal'>56</td>
                          <td className='normal'>1.69%</td>
                          <td className='normal epic'>Epic</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-4.png' alt='img' />
                              <span>Common</span>
                            </h4>
                          </td>
                          <td className='normal'>75</td>
                          <td className='normal'>2.25%</td>
                          <td className='normal epic'>Epic</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-3.png' alt='img' />
                              <span>Mythical</span>
                            </h4>
                          </td>
                          <td className='normal'>94</td>
                          <td className='normal'>2.82%</td>
                          <td className='normal classic'>classic</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-2.png' alt='img' />
                              <span>Rare</span>
                            </h4>
                          </td>
                          <td className='normal'>188</td>
                          <td className='normal'>5.64%</td>
                          <td className='normal classic'>classic</td>
                        </tr>
                        <tr>
                          <td>
                            <h4>
                              <img src='/images/nft/ignis-1.png' alt='img' />
                              <span>Common</span>
                            </h4>
                          </td>
                          <td className='normal'>2825</td>
                          <td className='normal'>84.75%</td>
                          <td className='normal classic'>classic</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className='genesis-nft tabs'>
            <div className='container'>
              <div className='nft-title text-center'>
                <h2>
                  Genesis NFT <span>Characters</span>
                </h2>
                <p>
                  Each NFT is a playable 3D Character giving you true
                  <br />
                  ownership of your in-game assets
                </p>
              </div>
              <ul className='tab-links clearfix'>
                <li className=''>
                  <button
                    onClick={() =>
                      setCurrentCharacter({
                        ignis: true,
                        oro: false,
                        penna: false,
                      })
                    }
                    className={` ${currentCharacter.ignis ? 'active' : ''}`}
                  >
                    Ignis
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      setCurrentCharacter({
                        ignis: false,
                        oro: true,
                        penna: false,
                      })
                    }
                    className={` ${currentCharacter.oro ? 'active' : ''}`}
                  >
                    Oro
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      setCurrentCharacter({
                        ignis: false,
                        oro: false,
                        penna: true,
                      })
                    }
                    className={` ${currentCharacter.penna ? 'active' : ''}`}
                  >
                    Penna
                  </button>
                </li>
              </ul>
            </div>
            <div className='tab-content'>
              <div
                id='ignis'
                className={`geneisis-char ignis tab ${
                  currentCharacter.ignis ? 'active' : ''
                }`}
              >
                <div className='container'>
                  <ul className='skin-links clearfix'>
                    <div className='skin-subtitle'>
                      <h3>Ignis</h3>
                    </div>
                    <li>
                      <button
                        id='ignis-1'
                        onClick={() =>
                          setCurrentSkin({
                            classic: true,
                            epic: false,
                            legendary: false,
                          })
                        }
                        className={` ${currentSkin.classic ? 'active' : ''}`}
                      >
                        classic
                      </button>
                    </li>
                    <li>
                      <button
                        id='ignis-2'
                        onClick={() =>
                          setCurrentSkin({
                            classic: false,
                            epic: true,
                            legendary: false,
                          })
                        }
                        className={`${currentSkin.epic ? 'active' : ''}`}
                      >
                        Epic
                      </button>
                    </li>
                    <li>
                      <button
                        id='ignis-3'
                        onClick={() =>
                          setCurrentSkin({
                            classic: false,
                            epic: false,
                            legendary: true,
                          })
                        }
                        className={` ${currentSkin.legendary ? 'active' : ''}`}
                      >
                        Legendary
                      </button>
                    </li>
                  </ul>
                  <div className='links-bg'>
                    <img
                      src='/images/nft/links-bg.png'
                      className='centered-img'
                      alt='img'
                    />
                  </div>
                  <div className='skin-wrapper ignis-wrapper'>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.classic ? 'active' : ''
                      }`}
                      data-btn='ignis-1'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-1.png' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>2825</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$90</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$8.10</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$81</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-2.png' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>188</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$120</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$10.80</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$108</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-3.png' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>94</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$150</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$13.50</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$135</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.epic ? 'active' : ''
                      }`}
                      data-btn='ignis-2'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-4.png' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>75</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$250</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$16.67</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$225</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-5.png' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>56</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$350</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$23.33</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$315</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-6.png' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>38</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$450</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$30.00</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$405</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.legendary ? 'active' : ''
                      }`}
                      data-btn='ignis-3'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-7.png' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>28</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$600</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$33.00</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$540</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-8.png' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>19</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$750</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$41.25</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$675</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/ignis-9.png' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply</td>
                              <td>9</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$900</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$49.50</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$810</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='oro'
                className={`geneisis-char oro tab ${
                  currentCharacter.oro ? 'active' : ''
                }`}
              >
                <div className='container'>
                  <ul className='skin-links clearfix'>
                    <div className='skin-subtitle'>
                      <h3>Oro</h3>
                    </div>
                    <li>
                      <button
                        id='oro-1'
                        onClick={() =>
                          setCurrentSkin({
                            classic: true,
                            epic: false,
                            legendary: false,
                          })
                        }
                        className={` ${currentSkin.classic ? 'active' : ''}`}
                      >
                        classic
                      </button>
                    </li>
                    <li>
                      <button
                        id='oro-2'
                        onClick={() =>
                          setCurrentSkin({
                            classic: false,
                            epic: true,
                            legendary: false,
                          })
                        }
                        className={` ${currentSkin.epic ? 'active' : ''}`}
                      >
                        Epic
                      </button>
                    </li>
                    <li>
                      <button
                        id='oro-3'
                        onClick={() =>
                          setCurrentSkin({
                            classic: false,
                            epic: false,
                            legendary: true,
                          })
                        }
                        className={` ${currentSkin.legendary ? 'active' : ''}`}
                      >
                        Legendary
                      </button>
                    </li>
                  </ul>
                  <div className='links-bg'>
                    <img
                      src='/images/nft/links-bg.png'
                      className='centered-img'
                      alt='img'
                    />
                  </div>
                  <div className='skin-wrapper oro-wrapper'>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.classic ? 'active' : ''
                      }`}
                      data-btn='oro-1'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-1.png?ver=2' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>2825</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$90</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$8.10</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$81</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-2.png?ver=2' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>188</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$120</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$10.80</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$108</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-3.png?ver=2' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>94</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$150</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$13.50</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$135</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.epic ? 'active' : ''
                      }`}
                      data-btn='oro-2'
                    >
                      <div className='skin-grid-item'>
                        <img src='images/nft/oro-4.png?ver=2' alt='img' />
                        <h3>
                          Common
                          <img src='images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>75</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$250</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$16.67</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$225</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-5.png?ver=2' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>56</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$350</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$23.33</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$315</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-6.png?ver=2' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>38</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$450</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$30.00</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$405</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.legendary ? 'active' : ''
                      }`}
                      data-btn='oro-3'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-7.png?ver=2' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>28</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$600</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$33.00</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$540</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-8.png?ver=2' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>19</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$750</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$41.25</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$675</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/oro-9.png?ver=2' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply</td>
                              <td>9</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$900</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$49.50</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$810</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id='penna'
                className={`geneisis-char penna tab ${
                  currentCharacter.penna ? 'active' : ''
                }`}
              >
                <div className='container'>
                  <ul className='skin-links clearfix'>
                    <div className='skin-subtitle'>
                      <h3>Penna</h3>
                    </div>
                    <li>
                      <button
                        id='penna-1'
                        onClick={() =>
                          setCurrentSkin({
                            classic: true,
                            epic: false,
                            legendary: false,
                          })
                        }
                        className={` ${currentSkin.classic ? 'active' : ''}`}
                      >
                        classic
                      </button>
                    </li>
                    <li>
                      <button
                        id='penna-2'
                        onClick={() =>
                          setCurrentSkin({
                            classic: false,
                            epic: true,
                            legendary: false,
                          })
                        }
                        className={` ${currentSkin.epic ? 'active' : ''}`}
                      >
                        Epic
                      </button>
                    </li>
                    <li>
                      <button
                        id='penna-3'
                        onClick={() =>
                          setCurrentSkin({
                            classic: false,
                            epic: false,
                            legendary: true,
                          })
                        }
                        className={` ${currentSkin.legendary ? 'active' : ''}`}
                      >
                        Legendary
                      </button>
                    </li>
                  </ul>
                  <div className='links-bg'>
                    <img
                      src='/images/nft/links-bg.png'
                      className='centered-img'
                      alt='img'
                    />
                  </div>
                  <div className='skin-wrapper penna-wrapper'>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.classic ? 'active' : ''
                      }`}
                      data-btn='penna-1'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-1.png?ver=2' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>2825</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$90</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$8.10</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$81</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-2.png?ver=2' alt='img' />
                        <h3>
                          Rare
                          <img src='images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>188</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$120</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$10.80</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$108</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-3.png?ver=2' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>94</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$150</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$13.50</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>180%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$135</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>300</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>10</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.epic ? 'active' : ''
                      }`}
                      data-btn='penna-2'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-4.png?ver=2' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>75</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$250</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$16.67</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$225</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-5.png?ver=2' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>56</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$350</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$23.33</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$315</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-6.png?ver=2' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>38</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$450</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$30.00</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>200%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$405</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>222%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>450</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>15</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`nft-skins-grid clearfix ${
                        currentSkin.legendary ? 'active' : ''
                      }`}
                      data-btn='penna-3'
                    >
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-7.png?ver=2' alt='img' />
                        <h3>
                          Common
                          <img src='/images/nft/common.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>28</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$600</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$33.00</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$540</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 5</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 15</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-8.png?ver=2' alt='img' />
                        <h3>
                          Rare
                          <img src='/images/nft/rare.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply </td>
                              <td>19</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$750</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$41.25</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$675</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 10</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 30</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className='skin-grid-item'>
                        <img src='/images/nft/penna-9.png?ver=2' alt='img' />
                        <h3>
                          Mythical
                          <img src='/images/nft/legendary.png' alt='img' />
                        </h3>
                        <div className='skin-details'>
                          <table>
                            <tr>
                              <td>total supply</td>
                              <td>9</td>
                            </tr>
                            <tr>
                              <td>value</td>
                              <td>$900</td>
                            </tr>
                            <tr>
                              <td>
                                <b>earing per hour</b>
                              </td>
                              <td>
                                <b>$49.50</b>
                              </td>
                            </tr>
                            <tr>
                              <td>total win roi</td>
                              <td>220%</td>
                            </tr>
                            <tr>
                              <td>recharge price</td>
                              <td>$810</td>
                            </tr>
                            <tr>
                              <td>post-recharge total win roi</td>
                              <td>244%</td>
                            </tr>
                            <tr>
                              <td>battles, total</td>
                              <td>600</td>
                            </tr>
                            <tr>
                              <td>battles per day </td>
                              <td>20</td>
                            </tr>
                            <tr>
                              <td>character level</td>
                              <td>max 15</td>
                            </tr>
                            <tr>
                              <td>ability level</td>
                              <td>max 45</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </section>
      </AppWrapper>
    </AppContainer>
  )
}

export default App
