import Sale from '../Sale'
import {
  MainContainer,
  MainWrapper,
  MainTitle,
  MainContainerInfo,
  MainTitleContainer,
  MainSubtitle,
  MainSubtitleContainer,
} from './Main.styled'

function Main({ userAddress, chainId }) {
  return (
    <MainContainer>
      <MainWrapper>
        <MainContainerInfo>
          <MainTitleContainer>
            <MainTitle>
              9,999
              <br /> Genesis Mysteries
            </MainTitle>
            <MainSubtitleContainer>
              <MainSubtitle>Only</MainSubtitle>
            </MainSubtitleContainer>
          </MainTitleContainer>
          <Sale userAddress={userAddress} chainId={chainId} />
        </MainContainerInfo>
      </MainWrapper>
    </MainContainer>
  )
}

export default Main
